import React from 'react'
import { Link } from 'gatsby'
import github from '../../img/github-icon.svg'
import logo from '../../images/logo-text.png'

const Navbar = class extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      active: false,
      navBarActiveClass: '',
    }
  }

  toggleHamburger = () => {
    // toggle the active boolean in the state
    this.setState(
      {
        active: !this.state.active,
      },
      // after state has been updated,
      () => {
        // set the class in state for the navbar accordingly
        this.state.active
          ? this.setState({
              navBarActiveClass: 'is-active',
            })
          : this.setState({
              navBarActiveClass: '',
            })
      }
    )
  }

  render() {
    return (
      <nav
        className="navbar is-fixed-top level"
        role="navigation"
        aria-label="main-navigation"
      >
        <div className="navbar__item">
          <a className="navbar__link" href="https://shop.dotslashfrag.com">Merch</a>
        </div>
        <div className="navbar__item">
          <a className="navbar__link" href="http://dsf.gg/discord">Discord</a>
        </div>
        <div className="navbar__item">
          <Link to="/" className="navbar__link" title="Logo">
            <img src={logo} alt="Kaldi" style={{ width: '88px' }} />
          </Link>
        </div>
        <div className="navbar__item">
          <a className="navbar__link" href="https://youtube.com/dotslashfrag?sub_confirmation=1">YouTube</a>
        </div>
        <div className="navbar__item">
          <a className="navbar__link" href="https://twitch.tv/dotslashfrag">Twitch</a>
        </div>
        <div className="navbar__item">
          <a className="navbar__link" href="/presskit">Presskit</a>
        </div>
      </nav>
    )
  }
}

export default Navbar
